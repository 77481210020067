@import url("https://fonts.googleapis.com/css2?family=Modak&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap");

:root {
  --highlight: #eee;
  --highlight-contrast: #9d0ce8;
  --bg: #2d3147;
  --bg-dark: #1c1e2b;
  --bg-subtle: #3c3c50;
  --sidebar-bg: #171923;
  --sidebar-drawer-bg: #292b3c;
  --text-main: #eee;
  --text-subtle: #cbd8e8;
  --text-highlight: #fff;
  --text-dark: #16161b;
  --input-outlines: #0d0d10;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

::-moz-selection {
  background: #ebc8fd;
  color: var(--text-dark);
}

::selection {
  background: #ebc8fd;
  color: var(--text-dark);
}

body {
  background: var(--bg-dark);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  color: #788594;
  font-weight: 500;
  text-decoration: none;
  font-size: 1.125rem;
  line-height: 1.6875rem;

  &.small {
    font-size: 0.8rem !important;
  }
}

h1 {
  color: #fff;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 2.9rem;
}

h2 {
  color: #fff;
  font-weight: 700;
  font-size: 2.1rem;
  line-height: 2.2rem;
  margin-top: 3 * 1.75rem;
  margin-bottom: 1 * 1.75rem;
}

h3 {
  color: #eee;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-top: 2 * 1.75rem;
  margin-bottom: 0.5 * 1.75rem;
}

a,
a:visited {
  text-decoration: none;
  color: #cd6fff;
  transition: 0.15s all ease;

  &:hover {
    color: var(--text-highlight);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

label {
  color: var(--text-subtle);
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 15px;
  display: block;
}

label.inline {
  display: inline;

  &:nth-of-type(2) {
    margin-left: 0.25rem;
  }
}

input {
  font-size: 13px;
  width: 100%;
}

.input-group {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  height: 30px;
}

input,
textarea {
  padding: 10px;
  background: #343952;
  border: none;
  color: var(--text-subtle);
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  transition: 0.3s all ease;

  &:hover {
    filter: brightness(1.2);
  }
}

input:focus,
textarea:focus,
textarea:active {
  outline: none;
}

button {
  cursor: pointer;
  background-color: var(--highlight-contrast);
  color: var(--text-subtle);
  border: none;
  border-radius: 4px;
  transition: 0.3s all ease;
  padding: 10px 15px;
  font-weight: 700;
  font-size: 13px;
  margin-top: 1rem;

  &:hover {
    background-color: var(--highlight-contrast);
    color: #fff;
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
  }
}

button.primary {
  background: var(--highlight-contrast);
  width: 130px;
  height: 50px;
  border: none;
  border-radius: 40px;
  color: #eee;
  font-size: 20px;
  font-weight: 500;
  margin: 0 1rem;
  transition: 0.3s all ease;
  position: relative;
  cursor: pointer;

  &:hover {
    transform: scale(1.08);
  }

  &.contrast {
    background: transparent;
    border: 2px solid #6b6d79;
  }
}

hr {
  border: none;
  border-bottom: 1px solid #5b5e7a;
  margin: 2rem 3rem;
}

.dropdown-wrapper {
  display: inline-block;
  color: #eee;
  font-size: 13px;
}

.dropdown-button {
  position: relative;
  cursor: pointer;
  padding: 0.5rem;
  background: #303244;
  border-radius: 15px;
  min-width: 97px;
  text-align: center;
  box-shadow: 0px 0px 1px 1px #00000054;

  &:hover {
    filter: brightness(1.2);
  }

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.dropdown {
  background: var(--sidebar-bg);
  position: absolute;
  border-radius: 4px;
  min-width: 97px;
  z-index: 98;
  box-shadow: 0px 0px 1px 1px #00000054;

  li {
    padding: 0.5rem;
    cursor: pointer;
    background: var(--sidebar-bg);

    &:hover {
      background: #303244;
    }

    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.selectCategory {
  width: 100%;
  min-height: 30px;
  font-size: 13px;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }

  input {
    color: var(--text-subtle) !important;
  }

  div[class$="control"] {
    background-color: #343952;
    border: none;
    cursor: pointer;
    transition: 0.3s all ease;

    &:hover {
      filter: brightness(1.2);
    }

    &:active,
    &:focus {
      border-color: var(--highlight);
      outline: none;
    }
  }

  div[class$="singleValue"] {
    color: var(--text-subtle);
  }

  div[class$="Input"] {
    color: var(--text-subtle);
  }

  div[class$="indicatorContainer"] {
    margin: 5px;
    padding: 0px 3px;

    &:hover {
      color: var(--text-subtle);
    }
  }

  div[class$="ValueContainer"] {
    font-size: 13px;
  }

  div[class$="menu"] {
    background-color: #343952;
    color: var(--text-subtle);

    & div > div {
      background-color: var(--bg);
      cursor: pointer;
    }

    & div > div:hover {
      filter: brightness(1.2);
    }
  }
}

.authForm {
  padding: 1rem;
  border-radius: 6px;
  background: var(--sidebar-drawer-bg);
  width: 375px;
  max-width: 100%;

  h1 {
    margin-bottom: 2rem;
    text-align: center;
  }

  label {
    font-size: 15px;
    margin: 1rem 0 1rem 0;
  }

  input {
    font-size: 1.1rem;
    border: none;
    background: #161925;
    padding: 10px;
    background: #343952;
    border: none;
    color: var(--text-subtle);
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    transition: 0.3s all ease;

    &:hover {
      filter: brightness(1.2);
    }
  }
}

@media screen and (max-width: 540px) {
  h1 {
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
}
